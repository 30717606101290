<template>
  <div class="child-todo-item">
    <form @submit.prevent="saveItem">
      <div class="form-group">
        <label for="title">Title</label>
        <input
          type="text"
          v-model="localItem.title"
          maxlength="20"
          id="title"
          class="form-control"
          required
        />
      </div>
      <div class="form-group">
        <label for="lead">Lead</label>
        <textarea
          v-model="localItem.lead"
          maxlength="200"
          id="lead"
          class="form-control"
          required
        ></textarea>
      </div>
      <div class="form-group">
        <MediaItem
          :file="file"
          v-if="file"
          :showDownload="false"
          @click="showDialog"
        ></MediaItem>
        <button type="button" @click="showDialog" v-else>
          <div class="media-item">
            <div class="media-content">
              <font-awesome-icon icon="file" class="media-icon" />
            </div>
            <div class="media-name">Dodaj plik</div>
          </div>
        </button>
      </div>
      <div class="form-group">
        <label>Type</label>
        <div class="type-options">
          <div
            v-for="(type, index) in types"
            :key="index"
            class="form-check"
            :class="{
              'first-type': index === 0,
              'last-type': index === types.length - 1,
            }"
          >
            <input
              type="radio"
              v-model="localItem.type"
              :value="type.id"
              :id="`type${index}`"
              class="form-check-input"
              name="type"
              required
            />
            <label :for="`type${index}`" class="form-check-label">{{ type.name }}</label>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="localItem.type === 1 || localItem.type === 2">
        <label for="useHtml">Use HTML</label>
        <input
          type="checkbox"
          v-model="localItem.useHtml"
          id="useHtml"
          class="form-check-input"
        />
      </div>
      <div class="form-group">
        <label for="text">Text/Pytanie</label>
        <textarea
          v-model="localItem.text"
          :rows="localItem.type > 2 ? 4 : 20"
          id="text"
          class="form-control"
        ></textarea>
      </div>
      <div v-if="localItem.type === 3 || localItem.type === 4">
        <div class="form-group">
          <div class="input-group">
            <label>Odpowiedź pierwsza</label>
            <input type="text" v-model="localItem.a1" class="form-control" />
            <div class="input-group-append">
              <div class="input-group-text">
                <label>Poprawna</label>
                <input type="checkbox" v-model="localItem.a1Correct" :true-value="1" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="useHtml">Odpowiedź druga</label>
            <input type="text" v-model="localItem.a2" class="form-control" />
            <div class="input-group-append">
              <div class="input-group-text">
                <label>Poprawna</label>
                <input type="checkbox" v-model="localItem.a2Correct" :true-value="1" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="useHtml">Odpowiedź trzecia</label>
            <input type="text" v-model="localItem.a3" class="form-control" />
            <div class="input-group-append">
              <div class="input-group-text">
                <label>Poprawna</label>
                <input type="checkbox" v-model="localItem.a3Correct" :true-value="1" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="useHtml">Odpowiedź czwarta </label>
            <input type="text" v-model="localItem.a4" class="form-control" />
            <div class="input-group-append">
              <div class="input-group-text">
                <label>Poprawna</label>
                <input type="checkbox" v-model="localItem.a4Correct" :true-value="1" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="useHtml">Odpowiedź piąta </label>
            <input type="text" v-model="localItem.a5" class="form-control" />
            <div class="input-group-append">
              <div class="input-group-text">
                <label>Poprawna</label>
                <input type="checkbox" v-model="localItem.a5Correct" :true-value="1" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label for="useHtml">Odpowiedź szósta </label>
            <input type="text" v-model="localItem.a6" class="form-control" />
            <div class="input-group-append">
              <div class="input-group-text">
                <label>Poprawna</label>
                <input type="checkbox" v-model="localItem.a6Correct" :true-value="1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary">Zapisz</button>
      </div>
    </form>
    <Dialog v-model:modelValue="isDialogVisible" title="Wybierz plik">
      <MediaList @file-clicked="handleAddFilesClicked" :showDownload="false"></MediaList>
      <template #footer>
        <button @click="hideDialog">Zamknij</button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import MediaItem from "./MediaItem.vue";
import TodoChildServices from "../../services/TodoChildServices";
import Dialog from "./Dialog.vue";
import MediaList from "./MediaList.vue";
import MediaService from "../../services/MediaService";

export default {
  components: {
    Dialog,
    MediaList,
    MediaItem,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.item.fileID) {
      MediaService.get(this.item.fileID).then((x) => {
        this.file = x.data;
      });
    }
  },
  data() {
    return {
      isDialogVisible: false,
      localItem: {},
      types: [
        {
          name: "Grupa",
          id: 1,
        },
        {
          name: "Strona",
          id: 2,
        },
        {
          name: "Pytanie",
          id: 3,
        },
        {
          name: "Egzamin",
          id: 4,
        },
      ],
      file: null,
    };
  },
  watch: {
    item: {
      handler(newItem) {
        this.localItem = { ...newItem };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleAddFilesClicked(file) {
      this.localItem.fileID = file.id;
      this.hideDialog();
      this.file = file;
    },
    saveItem() {
      if (this.localItem.pageID > 0) {
        TodoChildServices.saveChildItems(this.localItem).then(() => {
          this.$emit("update-item", { ...this.localItem });
        });
      } else {
        TodoChildServices.addChildItems([this.localItem]).then((x) => {
          this.localItem.pageID = x.data[0];
          this.$emit("update-item", { ...this.localItem });
        });
      }
    },
    showDialog() {
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>

<style scoped>
.child-todo-item {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #f9f9f9;
}
.label {
  margin-right: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  margin-right: 10px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
}

.form-check {
  margin-bottom: 10px;
}

.form-check-input {
  margin-right: 10px;
}

.input-group {
  display: flex;
}

.input-group label {
  margin-right: 10px;
}

.input-group-append {
  display: flex;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  background-color: #f1f1f1;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.type-options {
  display: flex;
  flex-wrap: wrap;
}
.type-options::before {
  content: "";
  width: 2%;
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  margin-bottom: 10px;
}
.type-options::after {
  content: "";
  width: 2%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}
.type-options .form-check {
  padding: 10px;
  border: 1px solid;
  display: block;
  text-align: center;
}

.type-options .first-type {
  border-left: none;
}

.type-options .last-type {
  border-right: none;
}

.type-options .form-check {
  flex: 1 0 20%; /* Each type option takes up 25% of the container */
}
</style>
